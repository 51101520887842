<template>
  <div>
    <v-container>
      <HandleCreateNewFYAdditionalDataGrp
      :dialog="showAdditionalDataGrpDialog"
      :response="aditionalDataResultObj"
      :programName="programName"
      @openCloseDialog="openCloseDialog"
      />
      <v-dialog
      v-model="isDialog"
      persistent
      max-width="60vw">
      <v-overlay :value="overlay">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-card
        class="CreateMtgNewFiscalYear">
          <v-card-title>
            Would you like to create New Fiscal Year For {{ nextFiscalYear }}?
          </v-card-title>
          <v-card-text>
            <v-btn
              color="blue darken-1"
              text
              @click="performAction"
            >
              Yes
            </v-btn>
            <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            Cancel
          </v-btn>
        </v-card-text>
      </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { calculateFiscalYears } from '../../../util/shared/tmc-global';
import { isEmptyObject } from '../../../util/shared/vue-global';
import HandleCreateNewFYAdditionalDataGrp from '../../common/createNewFiscalYear/HandleCreateNewFYAdditionalDataGrp.vue';

export default {
  name: 'MtgCreateFiscalYear',
  components: {
    HandleCreateNewFYAdditionalDataGrp,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    mtgBaseCrudKey: 'mtgTransaction',
    currentFiscalYear: undefined,
    nextFiscalYear: undefined,
    overlay: false,
    programName: '',
    showAdditionalDataGrpDialog: false,
    aditionalDataResultObj: {},
  }),
  created() {
    this.initializeDialogBox();
  },
  computed: {
    ...mapState({
      regYears: (state) => state.mtg.mtgTransaction.regYears,
    }),
    ...mapGetters('base/crud', [
      'criteria',
      'items',
    ]),
    ...mapGetters(['todaysDate']),
    isDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('dialogVal', value);
      },
    },
    fiscalYearList() {
      const registerdYears = (this.regYears || {});
      const allowAll = false;
      return calculateFiscalYears(registerdYears, allowAll);
    },
  },
  methods: {
    ...mapActions('mtg/mtgTransaction', [
      'loadFiscalRegYears',
    ]),
    ...mapActions('createNewFiscalYearManager', ['createNewFiscalYear']),
    initializeDialogBox() {
      this.loadFiscalRegYears({ baseCrudKey: this.mtgBaseCrudKey }).then(() => {
        const regYears = this.fiscalYearList || [];
        if ((regYears || []).length > 0) {
          this.currentFiscalYear = (regYears[regYears.length - 1] || {}).year;
          this.nextFiscalYear = this.currentFiscalYear + 1;
        }
      }).catch((e) => {
        console.warn(e);
      });
    },
    async handleCreateNewFiscalYearResult(resultObj) {
      if (!isEmptyObject(resultObj)) {
        if ((resultObj.componentStatusArr || []).length > 0) {
          this.showAdditionalDataGrpDialog = true;
          this.aditionalDataResultObj = resultObj;
          this.programName = resultObj.programName;
        }
      }
    },
    async mtgCreateNewFiscalYear() {
      const result = await this.createNewFiscalYear({
        programName: this.mtgBaseCrudKey,
        nextFiscalYear: this.nextFiscalYear,
        currentFiscalYear: this.currentFiscalYear,
      });
      return result;
    },
    async performAction() {
      this.overlay = true;
      const resultObj = await this.mtgCreateNewFiscalYear();
      this.handleCreateNewFiscalYearResult(resultObj);
      this.overlay = false;
      this.close();
    },
    close() {
      this.isDialog = false;
    },
    openCloseDialog(value) {
      this.showAdditionalDataGrpDialog = value;
    },
  },
};
</script>

<style>

</style>
